import React from 'react';

import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DoubleArrow from '@material-ui/icons/DoubleArrow';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import CountdownTimer from '../components/CountdownTimer';
import CancelButton from '../components/CancelButton';
import CreateButtonBay from '../components/CreateButtonBay';

/**
 * Renders the Cleaning Bay container
 * @Nate @todo: There could be multiple cleanings on the Bay at the same time accidently.
 * As we are removing validation we need to render all items on the list and process in Bay
 */
class Bay extends React.Component {

  //constructor
  constructor(props) {
    super(props);
    //handle state
    this.state = {
    };
  }

  //render
  render() {
    //handle props
    const bayNumber = this.props.bayNumber;
    const cleaning = this.props.cleaning;
    return (
      <Box //Content
        className={'bay-' + bayNumber}
        data-occupancy={(cleaning ? 'full' : 'empty')}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
        }}
      >

        <Box // Titlebar
          sx={{
            pb: 3,
          }}
        >
          <span className="circle-outline">
            <span className="number">{bayNumber}</span>
          </span>
          {(
            cleaning && !window.isTVApp ?

              (
                !this.props.isLoggedIn
                  ? <IconButton
                    aria-label="Login"
                    size="small"
                    href={"/user/login"}
                  >
                    <LockOpenIcon />
                  </IconButton>
                  : <><IconButton
                    aria-label="Edit"
                    size="small"
                    className="use-ajax"
                    data-dialog-options="{&quot;width&quot;:600}"
                    data-dialog-type="dialog"
                    href={"/cleaning/" + cleaning.drupal_internal__nid + "/16/edit?ignore_set_status=1"}
                  >
                    <EditIcon />
                  </IconButton>
                    <CancelButton id={cleaning.drupal_internal__nid} />
                    <IconButton
                      aria-label="Complete Cleaning"
                      size="small"
                      className="use-ajax"
                      data-dialog-options="{&quot;width&quot;:600}"
                      data-dialog-type="dialog"
                      href={"/cleaning/" + cleaning.drupal_internal__nid + "/16/edit"}
                    >
                      <DoubleArrow />
                    </IconButton>
                  </>
              )
              : ''
          )}

          {/* If no cleaning on the bay and it's loggedin add create button directly in Bay */}
          {(
            !cleaning && this.props.isLoggedIn ?
              <CreateButtonBay bayNumber={bayNumber} />

              : ''
          )}
        </Box>

        <Box //Basic Info
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              mb: 2,
            }}
          >
            <Typography component="h2">
              <Box
                sx={{
                  fontSize: {
                    xs: '1rem',
                    xl: '1.5rem',
                  },
                }}
              >
                {(cleaning ?
                  (cleaning.field_model && cleaning.field_model.field_make && cleaning.field_model.field_make.name ? cleaning.field_model.field_make.name : '')
                  + ' ' + (cleaning.field_model && cleaning.field_model.name ? cleaning.field_model.name : '')
                  + ' ' + (cleaning.field_colour && cleaning.field_colour.name ? cleaning.field_colour.name : '')
                  :
                  '')
                }
              </Box>
            </Typography>
            <Typography component="h3">
              <Box
                sx={{
                  fontSize: {
                    xs: '2rem',
                    xl: '2.5rem',
                  },
                }}
              >
                {(cleaning ? cleaning.field_service.name : '')}
              </Box>
            </Typography>
          </Box>
          <Typography component="div" className="cleaning-timer-container">
            <Box
              sx={{
                fontSize: {
                  xs: '3rem',
                  xl: '5rem',
                },
              }}
            >
              {
                (cleaning ?
                  <CountdownTimer
                    startDatetime={cleaning.field_bay_start_date}
                    serviceTimeMin={cleaning.field_service.field_time_average}
                    warningTimeMin="5" />
                  : ''
                )
              }
            </Box>
          </Typography>
        </Box>

        <Box // Flex Spacer
          sx={{
            visibility: 'hidden',
          }}
        ></Box>

      </Box>
    );
  }

}

export default Bay;