import React from 'react';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PeopleIcon from '@material-ui/icons/People';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import BrushIcon from '@material-ui/icons/Brush';
import StarIcon from '@material-ui/icons/Star';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Badge from '@material-ui/core/Badge';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Button, Box } from '@material-ui/core';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { List, ListItem, Divider, ListItemText } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import LocalCarWashIcon from '@material-ui/icons/LocalCarWash';

class Menu extends React.Component {

  //constructor
  constructor(props) {
    super(props);
    //handle state
    this.state = {
      open: false,
    };
  }

  //render
  render() {
    //handle props
    const cleaningsWaitingToBeAssigned = this.props.cleaningsWaitingToBeAssigned;
    const handleClickOpen = () => {
      this.setState({ open: true })
    };
    const handleClose = () => {
      this.setState({ open: false })
    };

    return (
      <div>
        <BottomNavigation className="menu">
          {!this.props.isLoggedIn
            ? <BottomNavigationAction label="Login" icon={<LockOpenIcon />} href={"/user/login"} />
            : <BottomNavigationAction
              label="Add Cleaning"
              icon={<AddIcon />}
              className="use-ajax"
              data-dialog-options="{&quot;width&quot;:700}"
              data-dialog-type="dialog"
              href={"/node/add/cleaning?destination=/"}
            />
          }
          <BottomNavigationAction label="Cleanings" icon={<LocalCarWashIcon />} href={"/admin/content/cleanings"} />
          <BottomNavigationAction label="People" icon={<PeopleIcon />} href={"/admin/people"} />
          <BottomNavigationAction label="Colour" icon={<ColorLensIcon />} href={"/admin/structure/taxonomy/manage/colour/overview"} />
          <BottomNavigationAction label="Cars" icon={<DirectionsCarIcon />} href={"/admin/structure/taxonomy/manage/model/overview"} />
          <BottomNavigationAction label="Services" icon={<BrushIcon />} href={"/admin/structure/taxonomy/manage/services/overview"} />
          <BottomNavigationAction label="Cleaning QA Services" icon={<StarIcon />} href={"/admin/structure/taxonomy/manage/cleaning_services/overview"} />
          <BottomNavigationAction label="Clipboard" icon={<Badge badgeContent={(cleaningsWaitingToBeAssigned ? cleaningsWaitingToBeAssigned.length : 0)} color="secondary"><AssignmentIcon /></Badge>} onClick={handleClickOpen} />
        </BottomNavigation>
        <Dialog
          open={this.state.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">New Car Submission</DialogTitle>
          <DialogContent>
            <DialogContent id="alert-dialog-description">
              <List>
                {
                  (cleaningsWaitingToBeAssigned && cleaningsWaitingToBeAssigned.length ?
                    cleaningsWaitingToBeAssigned.map(function (cleaning, index) {
                      let cleaningDate = new Date(cleaning.created);
                      return <React.Fragment key={cleaning.drupal_internal__nid}>
                        <ListItem alignItems='center'>
                          <ListItemText
                            primary={cleaningDate.toLocaleString("en-US", { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                            secondary={
                              <React.Fragment>
                                {"A " + cleaning.field_colour.name + " " + cleaning.field_model.field_make.name + " " + cleaning.field_model.name + " has been submitted for service."}
                              </React.Fragment>
                            }
                          />
                          <Box ml={4}>
                            <IconButton
                              aria-label="edit"
                              size="small"
                              className="use-ajax"
                              data-dialog-options="{&quot;width&quot;:600}"
                              data-dialog-type="dialog"
                              href={"/cleaning/" + cleaning.drupal_internal__nid + "/15/edit"}
                            >
                              <EditIcon />
                            </IconButton>
                          </Box>
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>;
                    })
                    : '')
                }
              </List>
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

}

export default Menu;