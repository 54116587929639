import React from 'react';

class CountdownTimer extends React.Component {

  //constructor
  constructor(props) {
    super(props);

    //Calculate the countdown time
    let startDatetime = new Date(this.props.startDatetime);
    let nowDatetime = new Date();
    let diffSeconds = Math.round((startDatetime.getTime() - nowDatetime.getTime()) / 1000);
    let countdownSeconds = diffSeconds + ((this.props.serviceTimeMin ? this.props.serviceTimeMin : 0) * 60);

    //handle state
    this.state = {
      time: {},
      seconds: countdownSeconds,
      overTime: false,
      warning: false,
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  startTimer() {
    if (this.props.startDatetime && this.props.serviceTimeMin && this.timer === 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      seconds: seconds,
      overTime: (seconds <= 0 ? true : false),
      warning: (seconds <= (this.props.warningTimeMin * 60) ? true : false),
    });
  }

  displayTime(seconds) {
    let totalSeconds = seconds;
    seconds = Math.abs(seconds);

    let time = [0, 0, 0]; //Hours, Minutes, Seconds
    let timeRemainder = seconds % 3600;
    time[0] = Math.floor(seconds / 3600); //Hours
    time[1] = Math.floor(timeRemainder / 60); //Minutes
    time[2] = timeRemainder % 60; //Seconds

    let timeString = '';
    //Show Negative
    if (totalSeconds < 0) {
      timeString += '-';
    }
    //Show Hours
    if (time[0] === 0) {
      timeString += '';
    } else {
      timeString += '';
      if (time[0] < 10) {
        timeString += '0';
      }
      timeString += `${time[0]}:\u200b`;
    }
    //Show Minutes
    if (time[1] < 10) {
      timeString += '0';
    }
    timeString += `${time[1]}:\u200b`;
    //Show Seconds
    if (time[2] < 10) {
      timeString += '0';
    }
    timeString += time[2];
    return timeString;
  }

  //render
  render() {
    this.startTimer();
    return (
      <span className={'countdownTimer ' + (this.state.overTime ? 'overtime ' : '') + (this.state.warning ? 'warning ' : '')}>
        {(this.props.startDatetime ? this.displayTime(this.state.seconds) : '')}
      </span>
    );
  }

}

export default CountdownTimer;