import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
const config = require('../config/globals');

/**
 * Create a cleaning directly from the Bay that auto populates the bay field
 * and shows all the fields available to fill out.
 * Pass default field values to prepopulate in case we use the same form
 */
class CreateButtonBay extends React.Component {

  //constructor
  constructor(props) {
    super(props);

    //handle state
    this.state = {
    };
  }

  //render
  render() {
    return (<>
      <IconButton
        aria-label="Create"
        size="small"
        className="use-ajax"
        data-dialog-options="{&quot;width&quot;:600}"
        data-dialog-type="dialog"
        href={`/cleaning/add?scope=bay&bay_id=${this.props.bayNumber}&status=${config.CLEANING_STATUS_IN_PROGRESS}`}
      >
        <AddIcon />
      </IconButton></>);
  }

}

export default CreateButtonBay;