import React from 'react';

import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { DataGrid } from '@material-ui/data-grid';
import DoubleArrow from '@material-ui/icons/DoubleArrow';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import Timer from '../components/Timer';
import CancelButton from '../components/CancelButton';

class WaitingToBeAssigned extends React.Component {

  //constructor
  constructor(props) {
    super(props);
    //handle state
    this.state = {
    };
  }


  //render
  render() {
    //handle props
    const cleanings = this.props.cleanings;
    //Setup Columns
    let columns = [
      {
        field: 'service',
        headerName: 'Service',
        flex: 3,
      },
      {
        field: 'make',
        headerName: 'Make',
        flex: 2,
      },
      {
        field: 'model',
        headerName: 'Model',
        flex: 2,
      },
      {
        field: 'colour',
        headerName: 'Colour',
        flex: 2,
      },
      {
        field: 'waitTime',
        headerName: 'Wait Time',
        renderCell: (cellValues) => {
          return <Timer startDatetime={cellValues.row.created} overTimeMin="10" warningTimeMin="5" />;
        },
        flex: 2,
      },
    ];
    if (!window.isTVApp) {
      columns.push(
        {
          field: 'actions',
          headerName: 'Actions',
          renderCell: (cellValues) => {
            return <>
              {
                !this.props.isLoggedIn
                  ? <IconButton
                    aria-label="Login"
                    size="small"
                    href={"/user/login"}
                  >
                    <LockOpenIcon />
                  </IconButton>
                  : <><IconButton
                    aria-label="Edit"
                    size="small"
                    className="use-ajax"
                    data-dialog-options="{&quot;width&quot;:600}"
                    data-dialog-type="dialog"
                    href={"/cleaning/" + cellValues.row.id + "/15/edit?ignore_set_status=1"}
                  >
                    <EditIcon />
                  </IconButton>
                    <CancelButton id={cellValues.row.id} />
                    <IconButton
                      aria-label="Select Package"
                      size="small"
                      className="use-ajax"
                      data-dialog-options="{&quot;width&quot;:600}"
                      data-dialog-type="dialog"
                      href={"/cleaning/" + cellValues.row.id + "/15/edit"}
                    >
                      <DoubleArrow />
                    </IconButton>
                  </>
              }
            </>


          },
          flex: 1,
        }
      );
    }

    const rows = (cleanings ?
      cleanings.map(cleaning => {
        return {
          id: cleaning.drupal_internal__nid,
          service: cleaning.field_service.name,
          model: cleaning.field_model.name,
          make: cleaning.field_model.field_make.name,
          colour: cleaning.field_colour.name,
          created: cleaning.created,
        };
      }) : []
    );

    return (
      <div>
        <Typography variant="h5" component="h2" gutterBottom>In Line</Typography>
        <Box className="title-underscore"></Box>
        <DataGrid
          rows={rows}
          rowHeight={(!window.isTVApp ? 52 : 104)}
          columns={columns}
          pageSize={3}
          rowsPerPageOptions={[3]}
          disableSelectionOnClick
          autoHeight
        />
      </div>
    );
  }

}

export default WaitingToBeAssigned;