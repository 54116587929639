import React from 'react';

import { Grid, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

class Stats extends React.Component {

  //constructor
  constructor(props) {
    super(props);
    //handle state
    this.state = {
    };
  }

  //render
  render() {
    //handle props
    const carsServed = this.props.carsServed;
    const avgTime = this.props.avgTime;
    const avgTimeTarget = this.props.avgTimeTarget;
    const carsInQueue = this.props.carsInQueue;
    const showElements = this.props.showElements;
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >

        <Grid container spacing={1}>
          {showElements.includes('cars-served') ? (
            <Grid item xs={12} lg={12}>
            <Box textAlign="center"><Typography variant="h6" component="h2" gutterBottom>Cars Served</Typography></Box>
            <Box textAlign="center"><Typography variant="h4" component="h2" gutterBottom>{carsServed}</Typography></Box>
          </Grid>
          ): ''}

          {showElements.includes('cars-in-queue') ? (
          <Grid item xs={12} lg={12}>
            <Box textAlign="center"><Typography variant="h6" component="h2" gutterBottom>Cars In Queue</Typography></Box>
            <Box textAlign="center"><Typography variant="h4" component="h2" gutterBottom>{carsInQueue}</Typography></Box>
          </Grid>
          ): ''}

          {showElements.includes('average-time') ? (
          <Grid item xs={12} lg={12}>
            <Box textAlign="center"><Typography variant="h6" component="h2" gutterBottom>Average Time</Typography></Box>
            <Box textAlign="center"><Typography variant="h4" component="h2" gutterBottom>{avgTime + ":00"}</Typography></Box>
          </Grid>
          ): ''}

          {showElements.includes('average-time-target') ? (
            <Grid item xs={12} lg={12}>
            <Box textAlign="center"><Typography variant="h6" component="h2" gutterBottom>Average Time Target</Typography></Box>
            <Box textAlign="center"><Typography variant="h4" component="h2" gutterBottom>{avgTimeTarget + ":00"}</Typography></Box>
          </Grid>
          ): ''}


        </Grid>

      </Box>
    );
  }

}

export default Stats;