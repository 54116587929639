import React from 'react';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { DataGrid } from '@material-ui/data-grid';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import DoubleArrow from '@material-ui/icons/DoubleArrow';

import Timer from '../components/Timer';
// eslint-disable-next-line
import CountdownTimer from '../components/CountdownTimer';
import CancelButton from '../components/CancelButton';


class OnTheWay extends React.Component {

  //constructor
  constructor(props) {
    super(props);
    //handle state
    this.state = {
    };
  }

  //render
  render() {
    //handle props
    const cleanings = this.props.cleanings;
    let columns = [
      {
        field: 'service',
        headerName: 'Service',
        flex: 3,
      },
      {
        field: 'make',
        headerName: 'Make',
        flex: 2,
      },
      {
        field: 'model',
        headerName: 'Model',
        flex: 2,
      },
      {
        field: 'colour',
        headerName: 'Colour',
        flex: 2,
      },
      {
        field: 'serviceTime',
        headerName: 'Service Time',
        renderCell: (cellValues) => {
          return <Timer startDatetime={cellValues.row.created} overTimeMin="10" warningTimeMin="5" />;
          // return <CountdownTimer
          //   startDatetime={cellValues.row.on_the_way_date}
          //   serviceTimeMin={cellValues.row.service_time_average}
          //   warningTimeMin="5" />;
        },
        sortable: false,
        flex: 2,
      },
    ];
    if (!window.isTVApp) {
      columns.push(
        {
          field: 'actions',
          headerName: 'Actions',
          renderCell: (cellValues) => {
            return <>
              {
                !this.props.isLoggedIn
                  ? <IconButton
                    aria-label="Login"
                    size="small"
                    href={"/user/login"}
                  >
                    <LockOpenIcon />
                  </IconButton>
                  : <><IconButton
                    aria-label="Edit"
                    size="small"
                    className="use-ajax"
                    data-dialog-options="{&quot;width&quot;:600}"
                    data-dialog-type="dialog"
                    href={"/cleaning/" + cellValues.row.id + "/45/edit?ignore_set_status=1"}
                  >
                    <EditIcon />
                  </IconButton>

                    <CancelButton id={cellValues.row.id} />

                    <IconButton
                      aria-label="Select Team Members"
                      size="small"
                      className="use-ajax"
                      data-dialog-options="{&quot;width&quot;:600}"
                      data-dialog-type="dialog"
                      href={"/cleaning/" + cellValues.row.id + "/45/edit"}
                    >
                      <DoubleArrow />
                    </IconButton>
                  </>
              }
            </>
          },
          sortable: false,
          flex: 1,
        },
      );
    }

    const rows = (cleanings ?
      cleanings.map(cleaning => {
        return {
          id: cleaning.drupal_internal__nid,
          service: cleaning.field_service.name,
          model: cleaning.field_model.name,
          make: cleaning.field_model.field_make.name,
          colour: cleaning.field_colour.name,
          created: cleaning.created,
          on_the_way_date: cleaning.field_on_the_way_date,
          service_time_average: cleaning.field_service.field_time_average,
        };
      }) : []
    );

    return (
      <div>
        <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 1 }}>On The Way</Typography>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={3}
          rowsPerPageOptions={[3]}
          disableSelectionOnClick
          autoHeight
        />
      </div>
    );
  }

}

export default OnTheWay;