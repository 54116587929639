import React from 'react';

class Timer extends React.Component {

  //constructor
  constructor(props) {
    super(props);

    //Calculate the countUp time
    let startDatetime = new Date(this.props.startDatetime);
    let nowDatetime = new Date();
    let diffSeconds = Math.round((startDatetime.getTime() - nowDatetime.getTime()) / 1000);

    //handle state
    this.state = {
      time: {},
      seconds: diffSeconds,
      overTime: false,
      warning: false,
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countUp = this.countUp.bind(this);
  }

  startTimer() {
    if (this.props.startDatetime && this.timer === 0) {
      this.timer = setInterval(this.countUp, 1000);
    }
  }

  countUp() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      seconds: seconds,
      overTime: (this.props.overTimeMin ? (seconds <= (-this.props.overTimeMin * 60) ? true : false) : false),
      warning: (this.props.warningTimeMin ? (seconds <= (-this.props.warningTimeMin * 60) ? true : false) : false),
    });
  }

  displayTime(seconds) {
    seconds = Math.abs(seconds);

    let time = [0, 0, 0]; //Hours, Minutes, Seconds
    let timeRemainder = seconds % 3600;
    time[0] = Math.floor(seconds / 3600); //Hours
    time[1] = Math.floor(timeRemainder / 60); //Minutes
    time[2] = timeRemainder % 60; //Seconds

    return (time[0] === 0 ? "" : (time[0] < 10 ? "0" : "") + time[0] + ":")
      + (time[1] < 10 ? "0" : "") + time[1] + ":"
      + (time[2] < 10 ? "0" + time[2] : time[2]);
  }

  //render
  render() {
    this.startTimer();
    return (
      <span className={'timer ' + (this.state.overTime ? 'overtime ' : '') + (this.state.warning ? 'warning ' : '')}>
        {(this.props.startDatetime ? this.displayTime(this.state.seconds) : '')}
      </span>
    );
  }

}

export default Timer;