import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';


class CancelButton extends React.Component {

  //constructor
  constructor(props) {
    super(props);

    //handle state
    this.state = {
    };
  }

  //render
  render() {
    return (<>
      <IconButton
        aria-label="Cancel"
        size="small"
        className="use-ajax"
        data-dialog-options="{&quot;width&quot;:600}"
        data-dialog-type="dialog"
        href={"/cleaning/" + this.props.id + "/cancel"}
      >
        <CancelIcon />
      </IconButton></>);
  }

}

export default CancelButton;