import React, { useState, useEffect } from "react";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Container, Grid, CssBaseline } from '@material-ui/core';

import socketIOClient from "socket.io-client";

// eslint-disable-next-line
import WaitingToBeAssigned from "./container/WaitingToBeAssigned";
import OnTheWay from "./container/OnTheWay";
import Bay from "./container/Bay";
import Stats from "./container/Stats";
import Menu from "./container/Menu";

/**
 * Global variables
 */
window.isTVApp = window.innerWidth >= 3840;

const ENDPOINT = process.env.REACT_APP_POLLING_API_URL;

function App() {

  const [cleanings, setCleanings] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  //Run once on app start
  useEffect(() => {
    // Check if user has access to create/edit content
    checkIsLoggedIn();
    fetchCleanings();

    const socket = socketIOClient(ENDPOINT);

    //When we see a change on the drupal cleanings
    socket.on("FromAPI", data => {
      console.log("FromAPI")
      //console.log(data);

      //Get the updated cleanings
      console.log("Updated Cleanings");
      fetchCleanings();
    });

    socket.on("ping", data => {
      console.log("ping")
      console.log(data)
    });
  // eslint-disable-next-line
  }, []);

  //Check if user is logged in
  function checkIsLoggedIn() {
    let doFetch = async () => {
      try {
        let url = "/node/add/cleaning";
        const response = await fetch(url);
        if (response.status === 200) setIsLoggedIn(true)

      } catch (error) {
        console.log("error", error);
      }
    }
    doFetch()
  }

  //Retrieve the cleanings data from the Drupal JSON API
  function fetchCleanings() {
    var now = new Date();
    var startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    var startOfDayUnixTimestamp = startOfDay / 1000;

    let url = "/jsonapi/node/cleaning?"
      + "include=field_colour,field_leader,field_service,field_status,field_team_members,field_model.field_make"
      + "&jsonapi_include=1"
      + "&filter[created_today][condition][path]=created"
      + "&filter[created_today][condition][operator]=%3E"
      + "&filter[created_today][condition][value]=" + startOfDayUnixTimestamp
      + "&sort=created"
      + "&page[limit]=500";

    let fetchData = async () => {
      try {
        const response = await fetch(url);
        const jsonData = await response.json();

        let cleanings = {};
        // waitingToBeAssigned
        cleanings.waitingToBeAssigned = parseCleaningsWaitingToBeAssigned(jsonData);
        // onTheWay
        cleanings.onTheWay = parseCleaningsOnTheWay(jsonData, jsonData);
        // inBay
        let inBay = [];
        for (let i = 1; i <= 6; i++) {
          inBay[i] = getCleaningsInBay(jsonData, i);
        }
        cleanings.inBay = inBay;
        // completed
        cleanings.completed = parseCleaningsCompleted(jsonData);

        // count
        cleanings.count = jsonData.meta.count;

        // avgTime
        cleanings.avgTime = Math.round(cleanings.completed.reduce((a, b) => a + (b.field_time_taken || 0), 0) / cleanings.completed.length);

        // avgTimeTarget
        cleanings.assigned = parseCleaningsAssigned(jsonData);
        cleanings.avgTimeTarget = Math.round(cleanings.assigned.reduce((a, b) => a + (b.field_service.field_time_average || 0), 0) / cleanings.assigned.length);

        setCleanings(cleanings);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }

  // @todo: Nate please use IDs instead of Strings. Names could potentially change
  // @see ./config/globals.js
  function parseCleaningsWaitingToBeAssigned(cleaningsData) {
    return cleaningsData.data.filter(function (cleaning) {
      return cleaning.field_status.name === 'Draft';
    });
  }

  function parseCleaningsOnTheWay(cleaningsData) {
    return cleaningsData.data.filter(function (cleaning) {
      return cleaning.field_status.name === 'On the way';
    });
  }

  function getCleaningsInBay(cleaningsData, bayNumber) {
    return cleaningsData.data.find(function (cleaning) {
      return cleaning.field_status.name === 'In progress'
        && cleaning.field_bay === bayNumber;
    });
  }

  function parseCleaningsCompleted(cleaningsData) {
    return cleaningsData.data.filter(function (cleaning) {
      return cleaning.field_status.name === 'Complete';
    });
  }

  function parseCleaningsAssigned(cleaningsData) {
    return cleaningsData.data.filter(function (cleaning) {
      return cleaning.field_status.name === 'On the way'
        || cleaning.field_status.name === 'In progress'
        || cleaning.field_status.name === 'Complete';
    });
  }


  //Use React - Material UI Dark mode by default
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'dark',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {/*"Width:" + window.innerWidth*/}
      <Container maxWidth={false}>

        <Grid container spacing={3} className="content-container">

          {!window.isTVApp ?
            <Grid item xs={12} style={{ margin: '1px' }}>

              {/* <Grid item xs={12} className="waiting-to-be-assigned">
                <WaitingToBeAssigned isLoggedIn={isLoggedIn} cleanings={(cleanings ? cleanings.waitingToBeAssigned : null)} />
              </Grid> */}

              <Grid item xs={12} className="on-the-way">
                <OnTheWay isLoggedIn={isLoggedIn} cleanings={(cleanings ? cleanings.onTheWay : null)} />
              </Grid>

            </Grid>
            :
            ''
          }

          <Grid item xs={12} sm={3} className="bay-slot top left">
            <Bay isLoggedIn={isLoggedIn} bayNumber={1} cleaning={(cleanings && cleanings.inBay[1] ? cleanings.inBay[1] : null)} />
          </Grid>

          <Grid item xs={12} sm={3} className="bay-slot top">
            <Bay isLoggedIn={isLoggedIn} bayNumber={3} cleaning={(cleanings && cleanings.inBay[3] ? cleanings.inBay[3] : null)} />
          </Grid>

          <Grid item xs={12} sm={3} className="bay-slot top">
            <Bay isLoggedIn={isLoggedIn} bayNumber={5} cleaning={(cleanings && cleanings.inBay[5] ? cleanings.inBay[5] : null)} />
          </Grid>

          <Grid item xs={12} sm={3} className="bay-slot top right">
            <Stats isLoggedIn={isLoggedIn}
            carsInQueue={(cleanings && cleanings.onTheWay.length ? cleanings.onTheWay.length : 0)}
            carsServed={(cleanings && cleanings.completed.length ? cleanings.completed.length : 0)}
            avgTime={(cleanings && cleanings.avgTime ? cleanings.avgTime : 0)}
            avgTimeTarget={(cleanings && cleanings.avgTimeTarget ? cleanings.avgTimeTarget : 0)}
            showElements={['average-time','average-time-target']} />
          </Grid>

          <Grid item xs={12} sm={3} className="bay-slot bottom left">
            <Bay isLoggedIn={isLoggedIn} bayNumber={2} cleaning={(cleanings && cleanings.inBay[2] ? cleanings.inBay[2] : null)} />
          </Grid>

          <Grid item xs={12} sm={3} className="bay-slot bottom">
            <Bay isLoggedIn={isLoggedIn} bayNumber={4} cleaning={(cleanings && cleanings.inBay[4] ? cleanings.inBay[4] : null)} />
          </Grid>

          <Grid item xs={12} sm={3} className="bay-slot bottom">
            <Bay isLoggedIn={isLoggedIn} bayNumber={6} cleaning={(cleanings && cleanings.inBay[6] ? cleanings.inBay[6] : null)} />
          </Grid>

          <Grid item xs={12} sm={3} className="bay-slot bottom right">
            <Stats isLoggedIn={isLoggedIn}
            carsInQueue={(cleanings && cleanings.onTheWay.length ? cleanings.onTheWay.length : 0)}
            carsServed={(cleanings && cleanings.completed.length ? cleanings.completed.length : 0)}
            avgTime={(cleanings && cleanings.avgTime ? cleanings.avgTime : 0)}
            avgTimeTarget={(cleanings && cleanings.avgTimeTarget ? cleanings.avgTimeTarget : 0)}
            showElements={['cars-served','cars-in-queue']} />
          </Grid>

        </Grid>

      </Container>

      {
        !window.isTVApp ?
          <Menu cleaningsWaitingToBeAssigned={cleanings.waitingToBeAssigned} isLoggedIn={isLoggedIn}></Menu>
          :
          ''
      }

    </ThemeProvider >
  );
}

export default App;